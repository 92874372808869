import { ToastState } from '@shared/models/toast-state';

export const TOAST_STATE = {
	default: 'default-toast',
	success: 'success-toast',
	warning: 'warning-toast',
	danger: 'danger-toast'
};

export const DEFAULT_TOAST_STATE: ToastState = {
	isLoading: false,
    dismissInSeconds: 3000,
	state: TOAST_STATE.default
};

export const LOADING_TOAST_STATE: ToastState = {
    isLoading: true,
    dismissInSeconds: 3000,
	state: TOAST_STATE.default
}

export const SUCCESS_TOAST_STATE: ToastState = {
    isLoading: false,
    dismissInSeconds: 3000,
	state: TOAST_STATE.success
}

export const ERROR_TOAST_STATE: ToastState = {
    isLoading: false,
    dismissInSeconds: 3000,
	state: TOAST_STATE.danger
}
