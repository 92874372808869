import { Injectable } from '@angular/core';
import { ModalService } from './modal.service';
import { BehaviorSubject } from 'rxjs';
import { DEFAULT_TOAST_STATE, TOAST_STATE } from '@shared/const/toast-state';
import { ToastState } from '@shared/models/toast-state';

@Injectable({
	providedIn: 'root'
})
export class ToastrService {
	public toastTitle$: BehaviorSubject<string> = new BehaviorSubject<string>(
		''
	);
	public toastMessage$: BehaviorSubject<string> = new BehaviorSubject<string>(
		''
	);
	public toastState$: BehaviorSubject<ToastState> =
		new BehaviorSubject<ToastState>(DEFAULT_TOAST_STATE);
	timeout: any;

	constructor(private modalService: ModalService) {}

	dismiss() {
		this.modalService.close('toastr-modal');
	}

	show(state: ToastState, message: string, title?: string) {
		this.dismiss();
		this.toastState$.next(state);
		this.toastTitle$.next(title ?? '');
		this.toastMessage$.next(message);
		this.modalService.open('toastr-modal');

		this.timeout = setTimeout(() => {
			this.dismiss();
		}, state.dismissInSeconds);
	}
}
